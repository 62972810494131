<template>
  <div class="vip-birthday">
    <div class="header">
      <divider-title text="会员生日" />
      <span class="label">*优惠券使用最终解释权归伊莉丝所有</span>
    </div>
    <div class="container">
      <div class="main">
        <div class="birthday-card">
          <div class="level">{{ baseInfo.level }}</div>
        </div>
        <div class="update-level">
          <img src="../../assets/images/arrow-up.png" alt />
          <span>{{ baseInfo.scoreStr }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <!-- <div class="desc">我是文字我是文字我是文字我是文字我是文字我是文字</div> -->
        <div class="gift-box">
          <div class="gift-item" v-for="(item, index) in giftList" :key="index">
            <div class="item-img" :class="'img'+index"></div>
            <div class="item-name">{{ item.title }}</div>
            <div class="item-desc">{{ item.des }}</div>
          </div>
        </div>
        <div :class="{'get-bag':true, disabled:!baseInfo.canreceive}" @click.once="getBirthBag" ref="bled">
          <i class="bag-img"></i>
          <span>{{name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dividerTitle from "@/components/dividerTitle";
import { GET_BIRTHDAYINFO, GET_BIRTHCARD } from "../../service/vipBirthday";

export default {
  name: "VipBirthday",
  components: { dividerTitle },
  data() {
    return {
      giftList: [
        { img: "", name: "生日礼物", desc: "我是文字我是文字我是文字我是文字" },
        { img: "", name: "生日礼物", desc: "我是文字我是文字我是文字我是文字" },
        { img: "", name: "生日礼物", desc: "我是文字我是文字我是文字我是文字" },
        { img: "", name: "生日礼物", desc: "我是文字我是文字我是文字我是文字" }
      ],
      baseInfo: {},
      name:'',
    };
  },
  computed: {},
  mounted() {
    this.getInfo();
    if(this.baseInfo.canreceive == 0){
      this.name = '领取生日礼包'
    }else{
      this.name = '已领取'
     this.$refs.bled.style='background:#ccc'
    }
  },
  methods: {
    getInfo() {
      GET_BIRTHDAYINFO({}).then(res => {
        console.log(res);
        this.baseInfo = res.attachment;
        let { key1, key2, key3, key4 } = this.baseInfo;
        this.giftList = [key1, key2, key3, key4];
      });
},
    getBirthBag() {
      if (!this.baseInfo.canreceive) {
        return;
      }
      GET_BIRTHCARD({}).then(res => {
        if (res.status === 200) {
          this.$message({
            type: "success",
            message: "领取生日礼包成功"
          });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.vip-birthday {
  background: #f5f5f5;
  box-shadow: 0px 4px 5px 2px #d6d6d6;
  .header {
    padding-top: 28px;
    height: 96px;
    background: #fff;
    position: relative;
    .label {
      position: absolute;
      right: 20px;
      bottom: 14px;
    }
  }
  .container {
    padding: 20px 52px 40px;
  }
  .main {
    padding: 54px 0 50px;
    background: #fff;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
    .birthday-card {
      width: 511px;
      height: 300px;
      margin: 0 auto;
      background-image: url("../../assets/images/birthday-card.png");
      background-size: 100% 100%;
      position: relative;
      .level {
        position: absolute;
        color: #fff;
        font-size: 30px;
        top: 116px;
        right: 80px;
      }
    }
    .update-level {
      display: flex;
      align-items: center;
      // width: 337px;
      width: fit-content;
      padding: 0 20px;
      height: 52px;
      border-radius: 26px;
      margin: 20px auto 15px;
      background: #f5f5f5;
      img {
        width: 20px;
        height: 20px;
        margin: 0 12px;
      }
      span {
        color: #666;
        font-size: 16px;
        margin-right: 12px;
        white-space: nowrap;
      }
    }
    .desc {
      width: 512px;
      color: #353535;
      font-size: 13px;
      line-height: 1.8;
      margin: 0 auto 32px;
    }
    .gift-box {
      display: flex;
      justify-content: space-between;
      background: #f5f5f5;
      margin: 0 20px 40px;
      padding: 20px;
      align-items: stretch;
      .gift-item {
        background: #fff;
        width: 183px;
        padding: 25px 10px;
        text-align: center;
        margin-right: 20px;
        .item-img {
          width: 75px;
          height: 75px;
          background-size: 100% 100%;
          margin: 0 auto;
        }
        .item-img.img0 {
          background-image: url("../../assets/images/vipbirthday-card1.png");
        }
        .item-img.img1 {
          background-image: url("../../assets/images/vipbirthday-card2.png");
        }
        .item-img.img2 {
          background-image: url("../../assets/images/vipbirthday-card3.png");
        }
        .item-img.img3 {
          background-image: url("../../assets/images/vipbirthday-card4.png");
        }
        .item-name {
          font-size: 14px;
          color: #333;
          margin: 12px 0 8px;
        }
        .item-desc {
          font-size: 10px;
          color: #666;
          // line-height: 15px;
          line-height: 1.5;
        }
      }
      .gift-item:last-child {
        margin-right: 0;
      }
    }
    .get-bag .disabled {
      background: #aaa;
      cursor: not-allowed;
    }
    .get-bag {
      width: 524px;
      height: 76px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: #dfb563;
      border-radius: 6px;
      font-size: 20px;
      margin: 0 auto;
      cursor: pointer;
      .bag-img {
        background-image: url("../../assets/images/gift-icon.png");
        height: 30px;
        width: 30px;
        background-size: 100% 100%;
        margin-right: 15px;
      }
    }
  }
}
</style>
